import React, { FC } from 'react';
import { Icon, IconName } from '@grafana/ui';

export interface Props {
  child: any;
}

const DropDownChild: FC<Props> = props => {
  const { child } = props;
  const listItemClassName = child.divider ? 'divider' : '';

  return (
    <li className={listItemClassName}>
      <a href={child.url}>
        <span className="icon-circle sidemenu-icon">{child.icon && <Icon name={child.icon as IconName} />}</span>
        <span className="sidemenu-title">{child.text}</span>
      </a>
    </li>
  );
};

export default DropDownChild;
