import React, { FC } from 'react';
import SideMenuDropDown from './SideMenuDropDown';
import { Icon } from '@grafana/ui';
import { NavModelItem } from '@grafana/data';

export interface Props {
  link: NavModelItem;
  onClick?: () => void;
}

const TopSectionItem: FC<Props> = props => {
  const { link, onClick } = props;

  return (
    <div className="sidemenu-item dropdown">
      <a className="sidemenu-link" href={link.url} target={link.target} onClick={onClick}>
        <span className="icon-circle sidemenu-icon">
          <Icon name={link.icon as any} size="xl" />
          {link.img && <img src={link.img} />}
        </span>
        <span className="sidemenu-title">{link.text}</span>
        {link.children && (
          <span className="sidemenu-toggle">
            <Icon name="angle-down" />
          </span>
        )}
      </a>
      <SideMenuDropDown link={link} />
    </div>
  );
};

export default TopSectionItem;
