import React, { FC } from 'react';
import _ from 'lodash';
import TopSectionItem from './TopSectionItem';
import config from '../../config';
import { getLocationSrv } from '@grafana/runtime';
import { contextSrv } from 'app/core/services/context_srv';

const TopSection: FC<any> = () => {
  const navTree = _.cloneDeep(config.bootData.navTree);
  let mainLinks = _.filter(navTree, item => !item.hideFromMenu);

  if (contextSrv.user.view === config.defaultView) {
    mainLinks = _.filter(mainLinks, item => !item.view);
  } else {
    mainLinks = _.filter(mainLinks, item => item.view === contextSrv.user.view);
  }

  const searchLink = {
    text: 'Search',
    icon: 'search',
  };

  const onOpenSearch = () => {
    getLocationSrv().update({ query: { search: 'open' }, partial: true });
  };

  return (
    <div className="sidemenu__top">
      <TopSectionItem link={searchLink} onClick={onOpenSearch} />
      {mainLinks.map((link, index) => {
        return <TopSectionItem link={link} key={`${link.id}-${index}`} />;
      })}
    </div>
  );
};

export default TopSection;
