import React, { FC } from 'react';
import { LinkButton } from '@grafana/ui';
import { css } from 'emotion';
import { getConfig } from 'app/core/config';

const signUpStyles = css`
  padding: 0px;
`;

export const UserSignup: FC<{}> = () => {
  const href = getConfig().verifyEmailEnabled ? `${getConfig().appSubUrl}/verify` : `${getConfig().appSubUrl}/signup`;
  return (
    <>
      <span>New to Grafana? </span>
      <LinkButton variant="link" className={signUpStyles} href={href}>
        Sign Up
      </LinkButton>
    </>
  );
};
